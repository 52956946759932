import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(26.000, 24.0000)">
        <path
          d="M 7.43,1.02
          C 4.78,3.92 5.06,5.15 5.00,9.00
            5.00,9.00 5.00,35.00 5.00,35.00
            5.00,37.78 4.71,44.58 5.74,46.85
            7.18,50.05 9.97,49.94 13.00,50.00
            13.00,50.00 27.00,50.00 27.00,50.00
            29.09,50.00 32.06,50.12 34.00,49.40
            36.35,48.54 38.24,46.72 39.98,44.98
            41.72,43.24 43.54,41.35 44.40,39.00
            45.41,36.25 45.03,20.96 45.00,17.00
            44.99,15.12 45.05,12.77 44.40,11.00
            43.00,7.19 36.98,1.31 33.00,0.16
            33.00,0.16 20.00,0.16 20.00,0.16
            15.81,0.00 11.36,-0.53 7.43,1.02 Z
          M 14.00,42.00
          C 14.00,42.00 14.00,8.00 14.00,8.00
            18.51,8.00 28.41,7.16 31.94,9.60
            33.89,10.95 35.11,12.71 35.54,15.00
            36.32,17.54 36.26,33.72 35.54,36.00
            34.72,37.81 33.54,39.30 31.94,40.40
            28.41,42.84 18.51,42.00 14.00,42.00 Z"
          fill="#64FFDA"
        />
      </g>
      <path
        stroke="#64FFDA"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
